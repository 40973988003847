import { ListView, Toast } from 'antd-mobile';
import { Loading,ErrorBlock,Space } from "antd-mobile-v5";
import React, { Component } from "react";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import { withRouter } from "react-router-dom";
import { fetchAll } from "../util/HttpUtil";
import { getUserId, formatTime } from "@/util/commonUtil";
import PostDetailReference from "./PostDetailReference"
import UserPortrait from "../components/UserPortrait"

import "../css/postdetail.less";
import "../css/readList.css";
import "react-photo-view/dist/index.css";
import sold_png from "@/icon/sold.png";
import bought_png from "@/icon/bought.png";
import {RightOutline, CheckOutline, CloseOutline} from 'antd-mobile-icons';

class ReviewDetail extends Component {
    chatUserId = null; //暂存私信发往的id
    noMoreRecords = false;
    postList = [];

    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => true,
        });

        this.state = {
            dataSource: dataSource.cloneWithRows({}),
            reply_div_display: "none",
            collect_svg: "collect_svg",
            sold_display:'none',
            collect_display:'block',
            reply_display:'block',
            chat_display:'block',
            page: 1,
        };
    }

    fetchData(url, isNewSearch) {
        this.setState({
            isLoading: true,
        });
        fetchAll(url)
            .then(res => {
                //没取到，表示到最后一页了
                if (res.result === "failed") {
                    this.noMoreRecords = true;
                    this.setState({
                        isLoading: false,
                        loadEmptyHint:"没找到哎,试试别的吧！",
                    });
                    return;
                }else{
                    this.noMoreRecords = false; //为了到达最后后，又重新刷新
                }

                this.postList = this.postList.concat(res.postOrComment);
                //没取到，表示到最后一页了
                if(isNewSearch){
                    this.props.refreshTitle(res.postOrComment.title)
                }
                this.setState({
                    dataSource: this.state.dataSource.cloneWithRows(this.postList),
                    isLoading: false,
                    page: this.state.page + 1,
                });

            })
            .catch(err => {
                this.noMoreRecords = true;
                this.setState({
                    isLoading: false,
                });
                // console.log(err);
                // Toast.fail(err);
            });
    }


    componentDidMount() {
        this.getPostDetail();
    }

    //退出时保存当前页信息，以便在返回时不重新加载，而是回到上次浏览的状态
    componentWillUnmount() {
        //防止报错：Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application
        this.setState = (state,callback)=>{
            return;
        };
    }

    getPostDetail(){
        let url =
            "/api/v1/admin/postreviews/"+this.props.postOrCommentId
        this.fetchData(url, true);
    }

    onScroll = () => {
    };

    checkIsLogin(){
        if (!getUserId()) {
            Toast.fail("还没登录呢,请登录后重试", 3, null, false);
            this.props.history.push('/pwdlogin');
            return false;
        }
        return true;
    }

    doReview = (id, action) => {
        let payload = {};
        payload.type = "review"
        payload.action = action
        fetchAll("/api/v1/admin/postreviews/" + id, payload, "POST")
            .then(res => {
                //没取到，表示到最后一页了
                if (res.result === "failed") {
                    Toast.fail(res.err, 3, null, false);
                }else{
                    this.props.history.replace('/admin/review');
                    Toast.info(res.msg, 3, null, false);
                }
            })
            .catch(err => {
            });
    }

    render() {
        const _renderSeparator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: "#F5F5F9",
                    height: 10,
                    borderTop: "1px solid #ECECED",
                    borderBottom: "1px solid #ECECED",
                }}
            />
        );
        //记录当前行
        let index=-1;
        const _renderRow = rowData => {
            const picList = [];
            index++;
            // 如果存在图片，放入elements数组
            if (rowData.pictures) {
                picList.push(
                    <PhotoProvider key={rowData.id+""+index}>
                        {rowData.pictures.map((item, index) => (
                            <div>
                                <PhotoConsumer key={rowData.id+""+index} src={item.url} >
                                    {/*<PhotoConsumer key={rowData.id+""+index} src={item.url} intro={item.thumbnailUrl}>*/}
                                    <img
                                        src={item.thumbnailUrl}
                                        alt=""
                                        style={{
                                            width: "200px",
                                            margin: "0 auto", //水平居中
                                            marginBottom: "10px",//下方有空
                                        }}
                                    />
                                </PhotoConsumer>
                                <br/>
                            </div>
                        ))}
                    </PhotoProvider>
                );
            }

            return (
                <div key={rowData.id}>
                    {rowData. parentPostId ?
                        <div className="portrait_and_time">
                            <div className={"detail_author_reply"}>
                                <UserPortrait
                                    portraitlUrl = {rowData.userInfo && rowData.userInfo.portraitThumbnailUrl ? rowData.userInfo.portraitThumbnailUrl : null}
                                    displayName = {rowData.userInfo && rowData.userInfo.displayName ? rowData.userInfo.displayName : "住这儿用户"}
                                />
                                <RightOutline/>
                                <UserPortrait
                                    portraitlUrl = {rowData.replyToUserInfo && rowData.replyToUserInfo.portraitThumbnailUrl ? rowData.replyToUserInfo.portraitThumbnailUrl : null}
                                    displayName = {rowData.replyToUserInfo && rowData.replyToUserInfo.displayName ? rowData.replyToUserInfo.displayName : "住这儿用户"}
                                />
                            </div>
                            <span style={{"fontSize":"12px"}}>{formatTime(rowData.createTime,'YYYY-MM-DD hh:mm:ss')}</span>
                        </div>
                        :
                        <div className="portrait_and_time">
                            <UserPortrait
                                portraitlUrl = {rowData.userInfo && rowData.userInfo.portraitThumbnailUrl ? rowData.userInfo.portraitThumbnailUrl : null}
                                displayName = {rowData.userInfo && rowData.userInfo.displayName ? rowData.userInfo.displayName : "住这儿用户"}
                            />
                            <span style={{"fontSize":"12px"}}>{formatTime(rowData.createTime,'YYYY-MM-DD hh:mm:ss')}</span>
                        </div>
                    }
                    {/* 加上whiteSpace:"pre"会有换行，但没遇到换行符不自动换行 */}
                    <div
                        className="post_article"
                        style={{
                            whiteSpace: "pre-wrap",
                            // overflow: "hidden",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                        }}
                    >
                        {rowData.reference
                            ? <PostDetailReference referenceAbstraction={rowData.reference} replyToUserInfo ={rowData.replyToUserInfo} parentPostId ={rowData.parentPostId} commentId = {rowData.replyToOriginCommentId}/>
                            : <div style={{"display":"none"}}/>
                        }
                        {rowData.content}
                        {rowData.category && rowData.category.startsWith("recycle") ?
                            (
                                rowData.type === "sell" ?
                                    <img src={sold_png} style={{"display":rowData.category && rowData.category.startsWith("recycle") && rowData.tags && rowData.tags.sold === true ? "block" : "none","position": "absolute", "left": "65%", "top": "5px", "zIndex": "999"}}/>
                                    :
                                    (
                                        rowData.type === "buy" ?
                                            <img src={bought_png} style={{"display":rowData.category && rowData.category.startsWith("recycle") && rowData.tags && rowData.tags.bought === true ? "block" : "none","position": "absolute", "left": "65%", "top": "5px", "zIndex": "999"}}/>
                                            :
                                            <img src={sold_png} style={{"display":rowData.category && rowData.category.startsWith("recycle") && rowData.tags && rowData.tags.given === true ? "block" : "none","position": "absolute", "left": "65%", "top": "5px", "zIndex": "999"}}/>
                                    )
                            )
                            :(<div style={{"display":"none"}}/>)
                        }

                    </div>
                    <div style={{ textAlign: "center" }}>{picList}</div>

                    <div className="detail_bottom">
                        <div className="house_svg" style={rowData.resiQuarterInfo ?{display:'block'}:{visibility:'hidden'}}/>
                        <div>{rowData.resiQuarterInfo ? rowData.resiQuarterInfo.name : ""} </div>
                        <div className="detail_remark">
                            <Space>
                                <div onClick={this.doReview.bind(this, rowData.reviewId, "pass")}>
                                    <CheckOutline fontSize={24}/>
                                </div>
                                <div onClick={this.doReview.bind(this, rowData.reviewId, "block")}>
                                    <CloseOutline fontSize={24}/>
                                </div>
                            </Space>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <div id="postlist_page">
                {this.postList.length === 0 && this.state.isLoading === true ?
                    (
                        <div style={{ textAlign: "center" }}>
                            加载中<Loading color="currentColor" />
                        </div>
                    ):
                    (
                        this.postList.length === 0 ?
                            (
                                <ErrorBlock description={
                                    <span>
                                        内容迷路了，试试别的吧！
                                    </span>
                                }>
                                </ErrorBlock>
                            )
                            :
                            <ListView
                                dataSource={this.state.dataSource}
                                initialListSize={10}
                                renderFooter={() => (
                                    <div key={"footer"} style={{ padding: 30, textAlign: "center" }}>
                                        {this.state.isLoading ? "加载中..." : "真的没有了哦"}
                                    </div>
                                )}

                                renderRow={_renderRow}
                                renderSeparator={_renderSeparator}
                                style={{
                                    height: document.documentElement.clientHeight,
                                    overflow: "auto",
                                }}
                                pageSize={10}
                                // onScroll={this.onScroll}
                                // onClick={() => {
                                //   console.log("scroclickll");
                                // }}
                                scrollRenderAheadDistance={500}
                                onEndReached={this.onEndReached}
                                onEndReachedThreshold={10}
                            />
                    )}
            </div>
        );
    }
}
export default withRouter(ReviewDetail);
